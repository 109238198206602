$(document).ready(function () {
  
// SEARCH STUFF START
    // Current Ajax request.
    var currentAjaxRequest = null;

    // Grabbing all search forms on the page, and adding a .search-results list to each.
    var searchForms = $('form[action="/search"]').css('position', 'relative').each(function () {
        // Grabbing text input.
        var input = $(this).find('input[name="q"]');
        // Adding a list for showing search results.
        var offSet = input.position().top + input.innerHeight();
        $('<ul class="search-results"></ul>').css({ 'position': 'absolute', 'left': '0px', 'top': offSet }).appendTo($(this)).hide();
        // Listening to keyup and change on the text field within these search forms.
        input.attr('autocomplete', 'off').bind('keyup change', function () {
            // What's the search term?
            var term = $(this).val();
            // What's the search form?
            var form = $(this).closest('form');
            // What's the search URL?
            var searchURL = '/search?type=product&q=' + term;
            // What's the search results list?
            var resultsList = form.find('.search-results');
            // If that's a new term and it contains at least 3 characters.
            if (term.length > 3 && term != $(this).attr('data-old-term')) {
                // Saving old query.
                $(this).attr('data-old-term', term);
                // Killing any Ajax request that's currently being processed.
                if (currentAjaxRequest != null) currentAjaxRequest.abort();
                // Pulling results.
                currentAjaxRequest = $.getJSON(searchURL + '&view=json', function (data) {
                    // Reset results.
                    resultsList.empty();
                    // If we have no results.
                    if (data.results_count == 0) {
                        resultsList.hide();
                    } else {
                        // If we have results.
                        $.each(data.results, function (index, item) {
                            var link = $('<a></a>').attr('href', item.url);
                            link.append('<span class="thumbnail"><img src="' + item.thumbnail + '" /></span>');
                            link.append('<span class="title">' + item.title + '</span>');
                            link.wrap('<li></li>');
                            resultsList.append(link.parent());
                        });
                        // The Ajax request will return at the most 10 results.
                        // If there are more than 10, let's link to the search results page.
                        if (data.results_count > 10) {
                            resultsList.append('<li><span class="title"><a href="' + searchURL + '">See all results (' + data.results_count + ')</a></span></li>');
                        }
                        resultsList.fadeIn(200);
                    }
                });
            }
        });
    });

    // Clicking outside makes the results disappear.
    $('body').bind('click', function () {
        $('.search-results').hide();
    });
// SEARCH STUFF END
  
    thumbnails = $('img[src*="/products/"]').not(':first');
    if (thumbnails.length) {
        thumbnails.bind('click', function () {
            var arrImage = $(this).attr('src').split('?')[0].split('.');
            var strExtention = arrImage.pop();
            var strRemaining = arrImage.pop().replace(/_[a-zA-Z0-9@]+$/, '');
            var strNewImage = arrImage.join('.') + "." + strRemaining + "." + strExtention;
            if (typeof variantImages !== 'undefined' && typeof variantImages[strNewImage] !== 'undefined') {
                productOptions.forEach(function (value, i) {
                    optionValue = variantImages[strNewImage]['option-' + i];
                    if (optionValue !== null && $('.single-option-selector:eq(' + i + ') option').filter(function () {
                            return $(this).text() === optionValue
                        }).length) {
                        $('.single-option-selector:eq(' + i + ')').val(optionValue).trigger('change');
                    }
                });
            }
        });
    }

    // Cart Sidebar
    var $L = 1200,
        $menu_navigation = $('#main-nav'),
        $cart_trigger = $('.cd-cart-trigger, a[data-cart-add]'),
        $hamburger_icon = $('#cd-hamburger-menu'),
        $lateral_cart = $('#cd-cart'),
        $shadow_layer = $('#cd-shadow-layer, .close-cart');

    //open lateral menu on mobile
    $hamburger_icon.on('click', function (event) {
        event.preventDefault();
        //close cart panel (if it's open)
        $lateral_cart.removeClass('speed-in');
        toggle_panel_visibility($menu_navigation, $shadow_layer, $('body'));
    });

    $('.forgot-password').on('click', function () {
        $('.kt-login').fadeOut(250, function(){
            $('.kt-login--forgot').fadeIn(250);
        });
    });

    $('.kt-login__forgot-cancel').on('click', function (e) {
        e.preventDefault()
        $('.kt-login--forgot').fadeOut(250, function(){
            $('.kt-login').fadeIn(250);
        });
    });

    if($('.kk-login').length){
        if(window.location.href.indexOf('#recover') > -1){
            $('.forgot-password').click();
        }
    }

    if(window.enable_ajax_cart){

        $(document).on('cart.requestComplete', function (event, cart) {
            $lateral_cart.addClass('speed-in').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function () {
                $('body').addClass('overflow-hidden');
            });
            $shadow_layer.addClass('is-visible');
            $("#quick-view-modal").fadeOut(300, function () {
                $("#quick-view-modal").remove()
            }), $("body").removeClass("modal-open"), !1
        });
        //open cart
        $('a[data-cart-add]').on('click', function (event) {
            console.log(this);
        });

        $cart_trigger.on('click', function (event) {
            // event.preventDefault();
            //close lateral menu (if it's open)
            $menu_navigation.removeClass('speed-in');
            toggle_panel_visibility($lateral_cart, $shadow_layer, $('body'));
        });

        var cartItems = 0;

        $(document).on('cart.requestComplete', function (event, cart) {
            if (cart.item_count == 0) {
                cartItems = "";
            } else {
                cartItems = '<span class="cartCount">' + cart.item_count + '</span>';
            }
            $('.cd-cart-trigger a').html('<i class="fas fa-shopping-cart"></i> ' + cartItems + ' Cart');
        });

        //close lateral cart or lateral menu
        $shadow_layer.on('click', function () {
            $shadow_layer.removeClass('is-visible');
            // firefox transitions break when parent overflow is changed, so we need to wait for the end of the trasition to give the body an overflow hidden
            if ($lateral_cart.hasClass('speed-in')) {
                $lateral_cart.removeClass('speed-in').on('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function () {
                    $('body').removeClass('overflow-hidden');
                });
                $menu_navigation.removeClass('speed-in');
            } else {
                $menu_navigation.removeClass('speed-in').on('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function () {
                    $('body').removeClass('overflow-hidden');
                });
                $lateral_cart.removeClass('speed-in');
            }
        });
    }

    //move #main-navigation inside header on laptop
    //insert #main-navigation after header on mobile
    function toggle_panel_visibility($lateral_panel, $background_layer, $body) {
        if ($lateral_panel.hasClass('speed-in')) {
            // firefox transitions break when parent overflow is changed, so we need to wait for the end of the trasition to give the body an overflow hidden
            $lateral_panel.removeClass('speed-in').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function () {
                $body.removeClass('overflow-hidden');
            });
            $background_layer.removeClass('is-visible');

        } else {
            $lateral_panel.addClass('speed-in').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function () {
                $body.addClass('overflow-hidden');
            });
            $background_layer.addClass('is-visible');
        }
    }

    document.querySelectorAll('.shopify-currency-form').forEach(function(element, index) {
        element.querySelector('select').addEventListener('change', function(){
            element.submit();
        });
    });

    // One click upsell
    if(window.upsell_enabled){

        var $clicked_el = false;

        $('[name="checkout"],[href*="checkout"]:not(.reject)').on('click', function(e){
            if(!$clicked_el){
                $clicked_el = $(this);
                $('.upsell-modal').modal(true);
                return false;
            }

            // anchors seemed to lose their clickability
            if($(this).attr('href')){
                window.location.href = $(this).attr('href');
            }
        });

        $('.upsell-modal').on('hide.bs.modal', function(){
            $clicked_el = false;
        });

        $('.upsell-modal [data-variant]').on('click', function(e){
            e.preventDefault();

            var variantid = $(this).data('variant');

            $(this).addClass('loading');

            $.ajax({
                url: '/cart/add.js',
                type: 'post',
                dataType: 'json',
                data: {
                    items: [
                        {
                          quantity: 1,
                          id: parseInt(variantid)
                        }
                    ]
                }
            }).done(function(){
                $clicked_el.click();
            });

        });

        $('.upsell-modal .reject').on('click', function(e){
            e.preventDefault();
            $clicked_el.click();
        });
    }
});


